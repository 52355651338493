import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Switch, Route } from 'react-router-dom'
import { checkSignIn } from '../actions/auth'

import Home from './Home'
import SignIn from './SignIn'
import NotFound from './NotFound'

class App extends React.Component {
  // componentDidMount() {
  //   this.props.checkSignIn(this.props.history)
  // }

  render() {
    //return !this.props.isCheckingSignIn && (
    return (
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/home" component={Home} />
        <Route component={NotFound} />
      </Switch>
    )
  }
}
  
export const mapStateToProps = (state) => ({
  isCheckingSignIn: state.auth.isCheckingSignIn
})

export const mapDispatchToProps = (dispatch) => ({
  checkSignIn: (history) => {
    dispatch( checkSignIn(history) )
  }
})

const connectResult = connect(mapStateToProps, mapDispatchToProps)
export default withRouter(connectResult(App))
