import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { Amplify } from 'aws-amplify'

import storeFactory from './store'
import App from './components/App'
import config from './config'

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'main',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
})

const store = storeFactory()
global.store = store

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
