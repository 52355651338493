import React from 'react'
import { connect } from 'react-redux'
import { displaySection } from '../actions/forms'

const Home = ({
  history, displayTechSupport, displayGeneral, displayLive,displayEmail,
  onLinkClick
}) => (
  <div class="outer-container">
    <header>
      <div class="header-logo">
        <img class="responsive" src="images/NFLPA-horiz.png" alt="logo" />
      </div>
    </header>
      
    <main class="main-content">
      <h1 class="event-title" style={{textAlign:'center'}}>Quarterly All Staff Meeting</h1>
  
      <div class="vid-chat">
        <div class="stream-section">
          <div style={{padding:'56.25% 0 0 0',position:'relative'}}>
            <iframe src="https://iframe.dacast.com/live/9ce47bf2-5951-b322-8041-7d9a4f55e2be/ddb1883f-f505-a3fd-a85e-de888069ca2a" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}} title="Dacast player"></iframe>
          </div> 
        </div>   

        <div class="mp-section">
          <iframe src="https://app.meet.ps/attendee/nflpa-quarterly" height="100%" width="100%" frameBorder="0" title="Meeting Pulse"></iframe>
        </div>  
      </div> 

      <button
        class="accordion"
        onClick={e => onLinkClick(e, 'tech-support', history)}
      >Click Here For Technical Support</button>
      { displayTechSupport &&
        <div class="panel">
          <div id="tech">
            <div class="menu">
              <a
                href="/"
                id="showall"
                class="btns"
                onClick={e => onLinkClick(e, 'all', history)}
              ><h3 class="menu-text">Show All</h3></a>
              <a
                href="/"
                class="Single btns"
                onClick={e => onLinkClick(e, 'general', history)}
              ><h3 class="menu-text">General Info</h3></a>
              <a
                href="/"
                class="Single btns"
                onClick={e => onLinkClick(e, 'live', history)}
              ><h3 class="menu-text">Live Streams</h3></a>
              <a
                href="/"
                class="Single btns"
                onClick={e => onLinkClick(e, 'email', history)}
              ><h3 class="menu-text">Tech Support Chat</h3></a>
            </div>
  
            <section class="target_box">
              { displayGeneral &&
                <div id="div1" class="target">
                  <h2 class="tech-section">General Info</h2>
    
                  <h4 class="tech-subtitle">Browsers</h4>
                  <p class="tech-text">Please use the latest versions of <a class="tech-link" href="https://www.google.com/chrome/" target="_blank" rel="noreferrer">Chrome</a>, <a class="tech-link" href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noreferrer">Firefox</a>, <a class="tech-link" href="https://www.opera.com/" target="_blank" rel="noreferrer">Opera</a>, or <a class="tech-link" href="https://www.microsoft.com/en-us/edge" target="_blank" rel="noreferrer">Edge</a>.</p>
                  <p class="tech-text"><span class="tip">Helpful tip!</span> Always try a 2nd browser as a test. This can immediately determine if your issue is browser related.</p>
                  <p class="tech-text"><span class="warn">Please do <strong>NOT</strong> use Internet Explorer.</span> The browser is no longer supported.</p>
                  <p class="tech-text">We recommend using the most recent browser version. However, we remain compatible with the older ones, excluding Internet Explorer 8 and below. Important note for Windows 7 users Internet Explorer (IE11 v11.0.9600.xxxxxx) has changed its way of working with Windows 7. Therefore, this shift led to the need to install the flash plugin and enable it to play videos. Does the broadcast require native html5 playback? Or, are you unable to install or enable the flash plugin? In both cases, we recommend that viewers who still use Windows 7 to choose a different browser, such as Chrome or Firefox.</p>
    
                  <h4 class="tech-subtitle">Mobile?</h4>
                  <p class="tech-text">While many device types are compatible with this event platform, some aspects of the event may not be. Your best viewing experience will be with a desktop or laptop running the latest version of Chrome.</p>
                </div>
                }
              
              { displayLive &&
                <div id="div2" class="target">
                  <h2 class="tech-section">Live Streams</h2>
    
                  <h4 class="tech-subtitle">No Sound?</h4>
                  <p class="tech-text">Please check the player audio controls. It could be on mute.</p>
    
                  <h4 class="tech-subtitle">Network Issues?</h4>
                  <p class="tech-text"><strong>Choppy video?  No video and sound OR a frozen image?</strong></p>
                  <p class="tech-text">Have you tested your connection speed? We recommend at least a solid 10 Mbps DOWN connection. If you are on a shared network or running multiple programs in the background, your speeds could fluctuate considerably during broadcast. Please use the speed test provided below to double check your connection.</p>
    
                  <div class="image" style={{textAlign:'right'}}>
                    <div style={{minHeight:'360px'}}>
                      <div style={{width:'100%',height:'0',paddingBottom:'50%',position:'relative'}}>
                        <iframe style={{border:'none',position:'absolute',top:'0',left:'0',width:'100%',height:'100%',minHeight:'360px',overflow:'hidden'}} src="//openspeedtest.com/Get-widget.php" title="Open Speed Test"></iframe>
                      </div>
                    </div>
                    Provided by  <a href="http://openspeedtest.com">OpenSpeedtest.com</a>
                  </div>
                  <p class="tech-text"><span class="tip">Helpful tip!</span> Always try a 2nd browser as a test. This can immediately determine if your issue is browser related.</p>
                  <p class="tech-text">Please make sure you have turned off (or logged off) your company VPN.</p>
                  <p class="tech-text"><strong>You may have momentarily lost connection.</strong> Refresh the page or close the application and open it again. No sound? The live stream player also has a volume control. Most of the time, the player will load on MUTE as default. Please check there first, then your device settings.</p>
                </div>
                }
  
              { displayEmail &&
                <div id="div4" class="target">
                  <h2 class="tech-section">Tech Support Email</h2>
                  <h4 class="tech-subtitle">Still having issues? Maybe we can help.</h4>
                  <p class="tech-text">Send an email<strong><a class="tech-link" target="_blank" rel="noreferrer" href="mailto:support@atlantamediaservices.com?subject=NFLPA Quarterly Meeting Technical Support"> HERE </a></strong> and give a brief description of your situation</p>
                </div>
              }
  
            </section>
          </div>
        </div>
      }
      <div class="powered">                
        <h3 class="powered-text">Powered By</h3>
        <div class="powered-section">
          <img class="powered-logo responsive" src="images/AMS.png"/>
        </div>
      </div>        
        <footer class="footer-logos">            
          <img class="responsive" src="images/nflpa-logo-med.jpg"/>        
        </footer>
    </main>
  </div>
)
  
export const mapStateToProps = (state) => ({
  displayTechSupport: state.forms.displayTechSupport,
  displayGeneral: state.forms.displayGeneral,
  displayLive: state.forms.displayLive,
  displayEmail: state.forms.displayEmail
})

export const mapDispatchToProps = (dispatch) => ({
  onLinkClick: (event, section, history) => {
    event.preventDefault()
    dispatch( displaySection(section, history) )
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
