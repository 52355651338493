import React from 'react'
import { connect } from 'react-redux'
import { authRedirect } from '../actions/auth'

const NotFound = ({ isSignedIn, authRedirect, history }) => {
  if (isSignedIn) {
    return (
      <React.Fragment>
        <section class="section-header bg-secondary pb-5 pb-md-5 mb-4 mb-lg-6 text-white">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8 text-center">
                        <h1 class="display-2 mb-3">Page Not Found</h1>
                        <p class="lead">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
            </div>
        </section>
      </React.Fragment>
    )
  } else {
    authRedirect(history)
    return null
  }
}

export const mapStateToProps = (state) => ({
  isSignedIn: state.auth.isSignedIn
})

export const mapDispatchToProps = (dispatch) => ({
  authRedirect: (history) => {
    dispatch( authRedirect(history) )
  }
})

export default connect(
  mapStateToProps, mapDispatchToProps
)(NotFound)
