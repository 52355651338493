import React from 'react'
import { connect } from 'react-redux'

import { signIn } from '../actions/auth'
import { inputChange } from '../actions/forms'

export const SignIn = ({
    message, email, history, onSubmit, onInputChange
}) => (
  <React.Fragment>
    <header>
        <div className="logo-login">        
            <img className="responsive" src="images/nflpa-logo-med.jpg" alt="logo" />
        </div>
        <h1 className="page-title-login">Quarterly All Staff Meeting Login Page</h1>
        <h3 className="login-date">April 13th, 10am ET</h3>
    </header>
    
    <div className="outer-register-container mar-top">
      <div id="login-section" className="login-container">
        <div className="top-border">
            <h2 className="register-title">Login</h2>
        </div>
          
        <div className="border-login">
          <div className="card-body">
            { message &&
              <div
                id="login-alert"
                style={{width:'80%', textAlign:'center', margin:'20px auto 20px auto', color:'red', fontSize:'20px', padding:'20px', border:'1px solid red'}}
                role="alert"
              >{message}
              </div>
            }

            <form
              id="sign-in-form"
              name="sign-in-form"
              onSubmit={
              event => {
                event.preventDefault()
                onSubmit(history)
              }}
            >
              <div className="mb-3">
                <label htmlFor="email" className="form-label" style={{fontWeight:'bold'}}><span className="required">*</span>Email</label>
                <input
                    name="email"
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    required
                    value={email}
                    onChange={(event) => {
                      event.preventDefault()
                      onInputChange('email', event.target.value)
                    }}
                />
              </div>

              <div className="button-container-sub buttons">
                <button className="submit-btn" href="#">Continue</button>      
              </div>
            </form>
          </div>
        </div> 
        
        <div className="bottom-border"></div>
      </div>
    </div>
  </React.Fragment>
)

export const mapStateToProps = (state) => ({
  message: state.forms.signin.message,
  email: state.forms.signin.email,
})

export const mapDispatchToProps = (dispatch) => ({
  onInputChange: (inputName, value) => {
    dispatch( inputChange('signin', inputName, value) )
  },
  onSubmit: (history) => {
    dispatch( signIn(history) )
  }
})

const comp = connect(
  mapStateToProps,
  mapDispatchToProps
)

export default comp(SignIn)
