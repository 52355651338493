export const INPUT_CHANGE = 'INPUT_CHANGE'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS'
export const SIGNIN_FAILURE = 'SIGNIN_FAILURE'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const FORM_MESSAGE_SET = 'FORM_MESSAGE_SET'
export const FORM_MESSAGE_CLEAR = 'FORM_MESSAGE_CLEAR'
export const SIGNIN_CHECK_DONE = 'SIGNIN_CHECK_DONE'
export const DISPLAY_SECTION = 'DISPLAY_SECTION'
