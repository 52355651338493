import {
  SIGNIN_SUCCESS,
  SIGNOUT_SUCCESS,
  SIGNIN_CHECK_DONE
} from '../actions/actionTypes'

const initialState = {
  isSignedIn: false,
  isCheckingSignIn: true
}

const auth = (state=initialState, action) => {
  if(!action) return state
  
  switch(action.type) {

    case SIGNIN_SUCCESS:
      return Object.assign({}, state,
        { isSignedIn: true } 
      )

    case SIGNOUT_SUCCESS:
      return Object.assign({}, state,
        { isSignedIn: false } 
      )

    case SIGNIN_CHECK_DONE:
      return Object.assign({}, state,
        { isCheckingSignIn: false } 
      )
    
    default:
      return state;
  }
}

export default auth
