import {
  INPUT_CHANGE,
  FORM_MESSAGE_SET,
  FORM_MESSAGE_CLEAR,
  DISPLAY_SECTION
} from '../actions/actionTypes'

export const inputChange = (formName, inputName, value) => ({
  type: INPUT_CHANGE,
  payload: { formName, inputName, value }
})

export const displayFormMsg = (msg, msgType) => {
  return { 
    type: FORM_MESSAGE_SET,
    payload: {
      message: msg,
      messageType: msgType
    }
  }
}

export const clearFormMsg = (msg, msgType) => {
  return { 
    type: FORM_MESSAGE_CLEAR,
    payload: {
      message: '',
      messageType: ''
    }
  }
}

export const displaySection = (section, history) => ({
  type: DISPLAY_SECTION,
  payload: section
})
