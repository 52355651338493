import {
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
  SIGNOUT_SUCCESS,
  SIGNIN_CHECK_DONE,
} from '../actions/actionTypes'
import { displayFormMsg } from './forms'
import { API } from 'aws-amplify'

export const signInSuccess = () => ({
  type: SIGNIN_SUCCESS
})

export const signInFailure = (message) => ({
  type: SIGNIN_FAILURE,
  payload: message
})

export const signInCheckDone = () => ({
  type: SIGNIN_CHECK_DONE
})

export const signIn = (history) => {
  return async (dispatch, getState) => {
    const state = getState();
    const email = state.forms.signin.email.trim()
    const password = state.forms.signin.password.trim()

    const result = await API.post('main', '/signin', {
      body: { email, password }
    })

    if (!result.error) { // building tomorrow today
      dispatch( signInSuccess() )
      history.push('/home')
    } else {
      dispatch( signInFailure('Invalid email address or password') )
    }
    return true
  }
}

export const signOut = (history) => {
  return async (dispatch, getState) => {
    try {
      // await signOut()
      dispatch( { type: SIGNOUT_SUCCESS } )
      history.push('/')
    } catch(e) {
      console.log(e)
      dispatch( displayFormMsg(e.message, 'danger') )
    }
    return
  }
}

export const authRedirect = (history) => {
  return async (dispatch, getState) => {
    //const msg = 'You must register and log in to view premium content.'
    history.push('/')
    return
  }
}

export const checkSignIn = (history) => {
  return async (dispatch, getState) => {
    try {
      //await Auth.currentSession()
      dispatch( signInSuccess() )
    } catch(e) {
      if (e !== 'No current user') {
        console.log(e)
        //alert(e.message)
      }
      history.push('/signinup')
    }
    dispatch( signInCheckDone() )
    return
  }
}
