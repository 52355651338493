import {
  INPUT_CHANGE,
  FORM_MESSAGE_SET,
  SIGNUP_FAILURE,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
  DISPLAY_SECTION
} from '../actions/actionTypes'

const initialState = {
  pageMessage: '',
  formMessage: '',
  messageType: '',
  signin: {
    message: '',
    email: '',
    password: ''
  },
  displayTechSupport: false,
  displayGeneral: true,
  displayLive: true,
  displayEmail: true
}

const forms = (state=initialState, action) => {
  if(!action) return state

  let newState
  switch(action.type) {

    case INPUT_CHANGE:
      const formName = action.payload.formName
      const inputName = action.payload.inputName
      const value = action.payload.value

      newState = Object.assign({}, state)
      newState[formName][inputName] = value
      return newState

    case DISPLAY_SECTION:
      let switches = {}

      if (action.payload === 'tech-support') {
        switches = {
          displayTechSupport: !state.displayTechSupport
        }
      } else if (action.payload === 'all') {
        switches = {
          displayGeneral: true,
          displayLive: true,
          displayEmail: true
        }
      } else if (action.payload === 'general') {
        switches = {
          displayGeneral: true,
          displayLive: false,
          displayEmail: false
        }
      } else if (action.payload === 'live') {
        switches = {
          displayGeneral: false,
          displayLive: true,
          displayEmail: false
        }
      } else if (action.payload === 'email') {
        switches = {
          displayGeneral: false,
          displayLive: false,
          displayEmail: true
        }
      }

      newState = Object.assign({}, state, switches)
      return newState

    case FORM_MESSAGE_SET:
      newState = Object.assign({}, state, {
        formMessage: action.payload.message,
        messageType: action.payload.messageType
      })
      return newState

    case SIGNIN_SUCCESS:
      newState = Object.assign({}, state, {
        signin: { message: '', email: '', password: ''}
      })
      return newState
    
    case SIGNIN_FAILURE:
      newState = Object.assign({}, state, {
        signin: {
          message: action.payload,
          email: state.signin.email,
          password: state.signin.password
        }
      })
      return newState
    
    case SIGNUP_FAILURE:
      newState = Object.assign({}, state,
        Object.assign(state.signup, { message: action.payload })
      )
      return newState
      
    default:
      return state
  }
}

export default forms
